<template>
  <v-container class="fill-height" fluid="">
    <v-row align="center" justify="center">
      <v-col align="center" justify="center">
        <v-card class="elevation-0" max-width="436px">
          <v-row align="center" justify="center">
            <v-col align="center" justify="center">
              <v-img
                class="ma-3"
                style="max-width:145px"
                src="@/assets/square-logo.svg"
              ></v-img>
              <v-card-title class="justify-center">
                <h3>Set password of Booking service</h3>
              </v-card-title>
              <v-card-text>
                <v-alert dark border="left" color="accent">
                  Введите новый пароль для логина
                  <strong>{{ $route.query.email }}</strong>
                </v-alert>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    outlined
                    id="password"
                    color="secondary"
                    dense
                    label="Новый пароль"
                    v-model="form.password"
                    @keypress.enter="enterToAccount"
                    :rules="rule.password"
                    name="password"
                    :type="viewPass ? 'text' : 'password'"
                    :append-icon="viewPass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="viewPass = !viewPass"
                    hide-details="auto"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="px-4">
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      rounded
                      class="black--text"
                      color="primary"
                      large="large"
                      block
                      @click="enterToAccount"
                      :disabled="!valid"
                      data-cy="login"
                      >Подтвердить</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import dbg from "@/plugins/dbg";
import validation from "@/mixins/validationRules";
import requestControl from "@/mixins/requestControl";
import { AUTH_PASSWORD_SET } from "../../../store/const/auth";
import { mapActions } from "vuex";
export default {
  mixins: [validation, requestControl],
  data() {
    return {
      form: {
        password: ""
      },
      serverErrors: {
        email: "",
        password: ""
      },
      viewPass: false,
      valid: true,
      isBlockButton: window.innerWidth >= 600,
      lazy: true,
      enterButtonLoading: false
    };
  },
  mounted() {
    this.$set(this.$store.state.Auth, "userToken", this.$route.query.token);
    localStorage.setItem("userToken", this.$route.query.token);
  },
  methods: {
    ...mapActions("Auth", {
      passwordSet: AUTH_PASSWORD_SET
    }),
    async enterToAccount() {
      const loginForm = this.$refs.form;
      const isValid = loginForm.validate();
      if (isValid) {
        await this.passwordSet({
          password: this.form.password,
          email: this.$route.query.email,
          token: this.$route.query.token
        });
        if (this.checkRequestOnError(AUTH_PASSWORD_SET)) {
          this.$notify({
            type: "succ",
            text: "Войдите под своим пользователем использовав новый пароль"
          });
          this.$router.push({ name: "login" });
        }
      } else {
        dbg("11error", loginForm);
      }
    }
  }
};
</script>
